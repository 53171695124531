const app = angular.module('editor');
import EvaluationTemplate from '../../views/directive/evaluation.jade'

app.directive('omEvaluation', function () {
    return {
        template: EvaluationTemplate,
        controller: 'OMEvaluation',
        scope: {
            score: '=?bind',
            size: '=?bind',
        }
    };
});

class OMEvaluationController {
    constructor($scope) {
        $scope.$watch('score', score => {
            $scope.list = [];
            $scope.score = Number(score);
            $scope.size = $scope.size || 16;
            $scope.size = $scope.size > 16 ? $scope.size : 16;
            for (let i = 0; i < Math.floor($scope.score); i++) $scope.list.push(1);
            if (Math.round($scope.score % 1 * 10) / 10) $scope.list.push(Math.round($scope.score % 1 * 10) / 10);
        })
    }
}

OMEvaluationController.$inject = ['$scope'];
app.controller('OMEvaluation', OMEvaluationController);