const app = angular.module('editor');

app.factory('$event', function () {
    const events = {};
    return {
        on: (type, key, callback) => {
            if (!key) throw Error('register event need key');
            if (!callback || typeof callback !== 'function') throw Error('register event need callback function');
            if (!events[type]) events[type] = {};
            if (!!events[type][key]) delete events[type][key];
            events[type][key] = callback;
        },
        emit: (type, ...args) => {
            if (!events[type]) return;
            for (let key in events[type]) events[type][key](...args);
        },
        cancel: (type, key) => {
            if (!events[type]) return;
            delete events[type][key];
        },
        clear: () => {
            for (let type in events) delete events[type];
        }
    };
});