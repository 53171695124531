const app = angular.module('editor');
import ResourceUploadTemplate from '../../views/directive/resource-upload.jade'

let syncOssFileListIndex = 0;

app.directive('omResourceUpload', function () {
    return {
        template: ResourceUploadTemplate,
        controller: 'OMResourceUpload',
        scope: {
            type: '=',
            gameId: '=',
            group: '=',
            onUpload: '=',
        }
    };
});

class OMResourceUploadController {
    constructor($scope, $rootScope, ApiService, $alert, $timeout, $util, $progress) {

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.ApiService = ApiService;
        this.$alert = $alert;
        this.$timeout = $timeout;
        this.$util = $util;
        this.$progress = $progress;

        this.OSSClient = null;
        $scope.typeAccept = {
            image: '.jpg,.png',
            gif: '.gif',
            audio: '.mp3,.wav',
            video: '.mp4',
        };

        $scope.uploader_queue = [];

        $scope.parseFileName = path => {
            if (!path) return '';
            return path.replace('puzzle/games/' + $scope.gameId + '/', '');
        };
        $scope.parseUploadStatusName = status => {
            if (status === 0) return '上传中…';
            if (status === 1) return '上传成功';
            if (status === 2) return '上传失败';
        };
        $scope.parseUploadStatusClass = status => {
            if (status === 0) return 'text-secondary';
            if (status === 1) return 'text-success';
            if (status === 2) return 'text-danger';
        };

        $scope.parseFileKey = path => {
            let name = path.replace('puzzle/games/' + $scope.gameId + '/', '');
            return 'nrk_' + this.getStringSign(name);
        };

        $scope.parseFileFormat = path => {
            if (path.endsWith('/')) return 'dir';
            let arr = path.split('.');
            return arr[arr.length - 1];
        };

        $scope.$watch('type', type => {
            if (type) {
                $scope.fileType = type;
            } else {
                $scope.fileType = 'image';
            }
        });
        $scope.$watch('files', async files => {
            if (files) {
                let group = '';
                if ('image' === $scope.fileType && $scope.group && $scope.group !== 'no_group') {
                    group = $scope.group;
                }
                if (group) group = group.endsWith('/') ? group : group + '/';
                let pattern = new RegExp("[`+*~^&()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？%]");
                for (let file of files) {
                    let _path = `puzzle/games/${$scope.gameId}/${group}${file.name}`;
                    $scope.uploader_queue.push({name: _path, status: 0, size: file.size});
                    let index = $scope.uploader_queue.length - 1;
                    if (pattern.test(file.name)) {
                        $scope.uploader_queue[index].status = 2;
                        this.$alert.error('文件名不允许有特殊字符!');
                        continue;
                    }
                    let filename_split_arr = file.name.split('.');
                    let format = filename_split_arr[filename_split_arr.length - 1].toLowerCase();
                    if (!['jpg', 'png', 'gif', 'mp3', 'wav', 'mp4'].includes(format)) {
                        $scope.uploader_queue[index].status = 2;
                        this.$alert.error('不支持的文件格式');
                        continue;
                    }
                    if ($scope.fileType === 'image' && file.size > 2 * 1024 * 1024) {
                        $scope.uploader_queue[index].status = 2;
                        this.$alert.error('图片大小不能超过2M!');
                        continue;
                    }
                    if ($scope.fileType === 'gif' && file.size > 5 * 1024 * 1024) {
                        $scope.uploader_queue[index].status = 2;
                        this.$alert.error('动图大小不能超过5M');
                        continue;
                    }
                    if ($scope.fileType === 'video') {
                        let url = URL.createObjectURL(file);
                        let duration = await this.getVideoDuration(url);
                        if (!file.type.endsWith('mp4')) {
                            $scope.uploader_queue[index].status = 2;
                            this.$alert.error('不支持的文件格式');
                            continue;
                        }
                        if (duration > 5 * 60) {
                            $scope.uploader_queue[index].status = 2;
                            this.$alert.error('视频时长不能超过5分钟');
                            continue;
                        }
                    }
                    try {
                        if (!this.OSSClient) throw Error('上传服务未准备好');
                        await this.OSSClient.multipartUpload(_path, file);
                        $scope.uploader_queue[index].status = 1;
                    } catch (error) {
                        $scope.uploader_queue[index].status = 2;
                        console.error(error);
                    }
                }
                this.$timeout(() => $scope.$apply());
                await this.syncOssFileList();
            }
        });

        this.initGameSTSToken().then();
    }

    async initGameSTSToken() {
        try {
            let credentials = await this.ApiService.getGameSTSToken(this.$scope.gameId);
            console.log('getSTSToken success')
            this.OSSClient = new OSS({
                region: 'oss-cn-beijing',
                accessKeyId: credentials.AccessKeyId,
                accessKeySecret: credentials.AccessKeySecret,
                stsToken: credentials.SecurityToken,
                bucket: 'om-resources',
                endpoint: 'resource.itaotuo.com',
                cname: true,
            });
            this.$timeout(() => this.initGameSTSToken(), new Date(credentials.Expiration) - new Date() - 30000);
        } catch (e) {
            console.error('getGameSTSToken:error:', e);
        }
    }


    // 同步OSS资源库数据
    async syncOssFileList() {
        let _index = syncOssFileListIndex++;
        this.$progress();
        console.log('on syncOssFileList id:', _index);
        let self = this;
        let list = await this.listDir('puzzle/games/' + this.$scope.gameId + '/');
        let $set = {};
        let $unset = {};
        let keys = {};
        let resources = this.$rootScope.__.GAMES[this.$scope.gameId].dev.others.resources || {};
        // 检查新增资源
        for (let item of list) {
            let format = this.$scope.parseFileFormat(item.name);
            let type = this.$rootScope.__.getGameResourceType({format});
            if (!type) continue;
            let key = this.$scope.parseFileKey(item.name);
            if (!resources[key]) {
                let name = item.name.replace('puzzle/games/' + this.$scope.gameId + '/', '');
                key = 'nrk__' + this.getStringKey(name);
            }
            keys[key] = 1;
            let resource = resources[key];
            if (!resource || !resource.lastModified || resource.lastModified < item.lastModified) {
                let re = {
                    key,
                    name: this.$scope.parseFileName(item.name),
                    lastModified: item.lastModified,
                    size: item.size,
                    type,
                    format,
                };
                if (resource) {
                    if (resource.interval) re.interval = resource.interval;
                }
                if (self.isImage(item.name)) {
                    try {
                        let imgInfo = await self.getImageInfoByOSS(this.parseFileUrl(re.name));
                        re.ImageHeight = imgInfo.ImageHeight.value;
                        re.ImageWidth = imgInfo.ImageWidth.value;
                        // if (re.ImageWidth > 2048 || re.ImageHeight > 2048) {
                        //     this.$alert.error(`图片${re.name}尺寸超出2048×2048, 将无法用于游戏内容!`)
                        // }
                        // if (re.size > 1048576) {
                        //     this.$alert.error(`图片${re.name}大小超出1M, 将无法用于游戏内容!`)
                        // }
                    } catch (error) {
                        console.error(error);
                    }
                }

                if (self.isAudio(item.name)) {
                    try {
                        let url = 'https://resource.itaotuo.com/' + item.name;
                        re.duration = await this.getAudioDuration(url);
                    } catch (error) {
                        console.error(error);
                    }
                }
                if (self.isVideo(item.name)) {
                    try {
                        let url = 'https://resource.itaotuo.com/' + item.name;
                        let frame_url = url + '?x-oss-process=video/snapshot,t_1,f_jpg,m_fast';
                        let imgInfo = await this.getImageSize(frame_url);
                        re.duration = await this.getVideoDuration(url);
                        re.ImageHeight = imgInfo.ImageHeight;
                        re.ImageWidth = imgInfo.ImageWidth;

                    } catch (error) {
                        console.error(error);
                    }
                }
                $set[key] = re;
            }
        }
        // 更新新增资源
        if (this.$util.getObjectSize($set)) await this.postGameResources($set);
        // 检查删除资源
        for (let key in resources) {
            let type = resources[key].type;
            if (!type) continue;
            if ('imageAndText' === type) continue;
            if (resources.hasOwnProperty(key) && !keys[key]) $unset[key] = resources[key];
        }
        // 更新删除资源
        if (this.$util.getObjectSize($unset)) await this.deleteResources($unset);
        console.log('end syncOssFileList id:', _index);
        this.$progress.end();
    }

    async listDir(dir, marker, arr = []) {
        if (!this.OSSClient) throw Error('上传服务未准备好');
        let value = await this.OSSClient.list({
            prefix: dir,
            marker: marker,
            'max-keys': 1000
        });
        if (!value.objects) return arr;
        for (let item of value.objects) arr.push(item);
        if (value.isTruncated) return await this.listDir(dir, value.nextMarker, arr);
        else return arr;
    }

    async getImageSize(url) {
        let imageDom = new Image();
        imageDom.src = url;
        return new Promise(resolve => {
            imageDom.onload = function () {
                let ImageWidth = imageDom.naturalWidth || imageDom.width;
                let ImageHeight = imageDom.naturalHeight || imageDom.height;
                resolve({ImageWidth, ImageHeight});
            }
        })
    }

    async getAudioDuration(url) {
        let audioDom = new Audio();
        audioDom.src = url;
        return new Promise(resolve => {
            audioDom.onloadedmetadata = function () {
                resolve(audioDom.duration);
            }
        })
    }

    async getVideoDuration(url) {
        let videoDom = document.createElement('video');
        videoDom.src = url;
        return new Promise(resolve => {
            videoDom.onloadedmetadata = function () {
                resolve(videoDom.duration);
            }
        })
    }

    isImage(path) {
        if (!path) return false;
        let arr = path.split('.');
        let format = arr[arr.length - 1].toLowerCase();
        return ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp'].includes(format);
    }

    isAudio(path) {
        if (!path) return false;
        let arr = path.split('.');
        let format = arr[arr.length - 1].toLowerCase();
        return ['mp3'].includes(format);
    }

    isVideo(path) {
        if (!path) return false;
        let arr = path.split('.');
        let format = arr[arr.length - 1].toLowerCase();
        return ['mp4'].includes(format);
    }

    async getImageInfoByOSS(url) {
        let data = await OSS.urllib.request(url + '?x-oss-process=image/info', {method: 'GET'});
        return JSON.parse(data.data);
    }

    getStringSign(string) {
        let temp = encodeURIComponent((Buffer.from(string)).toString('base64')).replace(/%../g, '');
        if (temp.length > 28) {
            let str = '', d = Math.floor((temp.length - 12) / 16);
            for (let i = 3; i < temp.length - 8; i += (d + 1)) str += temp[i];
            return temp.substring(0, 4) + str.substring(0, 16) + temp.substring(temp.length - 8);
        }
        return temp;
    }

    /**
     * getStringKey
     * @param string {String}
     */
    getStringKey(string) {
        let result = '';
        for (let i = 0; i < string.length; i++) {
            let num = string.charCodeAt(i);
            let s_62 = '';
            let dict = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
            if (dict.includes(string[i])) result += string[i];
            else {
                do {
                    s_62 = dict.charAt(num % 62) + s_62;
                    num = Math.floor(num / 62);
                } while (num > 0)
                result += s_62;
            }
        }
        return result;
    }

    parseFileUrl(name) {
        if (!name) return '';
        return `https://resource.itaotuo.com/puzzle/games/${this.$scope.gameId}/${name}`;
    }

    async postGameResources(resources) {
        console.log('post', Object.values(resources).map(r => r.name));
        try {
            let result= await this.ApiService.postGameResources(this.$scope.gameId, resources);
            if (result && result.others) {
                this.$rootScope.__.GAMES[this.$scope.gameId].dev.others.resources = result.others.resources;
            }
            this.$scope.onUpload();
        } catch (e) {
            console.error(e);
            this.$alert.error(e.data && e.data.status && e.data.status.message);
        }
    }

    async deleteResources(resources) {
        console.log('delete', Object.values(resources).map(r => r.name));
        let $unset = {};
        for (let key in resources) {
            $unset[key] = resources[key].name;
        }
        try {
            // await this.ApiService.deleteGameResources(this.$scope.gameId, $unset);
            this.$scope.onUpload();
        } catch (e) {
            console.error(e);
            this.$alert.error(e.data && e.data.status && e.data.status.message);
        }
    }

}

OMResourceUploadController
    .$inject = ['$scope', '$rootScope', 'ApiService', '$alert', '$timeout', '$util', '$progress'];
app
    .controller(
        'OMResourceUpload'
        ,
        OMResourceUploadController
    )
;
