const app = angular.module('editor');
import PaginationTemplate from '../../views/directive/pagination.jade'

app.directive('omPagination', function () {
    return {
        template: PaginationTemplate,
        controller: 'OMPagination',
        scope: {
            data_size: '=?size',
            data_page: '=?page',
            data_limit: '=?limit',
            onchange: '=onchange',
        }
    };
});

class OMPaginationController {
    constructor($scope) {
        this.$scope = $scope;
        //数据
        $scope.data_size = $scope.data_size || 0;
        $scope.data_page = $scope.data_page || 1;
        $scope.data_limit = $scope.data_limit || 10;

        $scope.$watch('data_size', () => {
            this.init()
        });
        $scope.$watch('data_page', value => {
            $scope.onchange && $scope.onchange(value);
        });

        $scope.lastPageList = () => {
            $scope.pagination_page--;
            if ($scope.pagination_page < 1) return $scope.pagination_page = 1;
            $scope.data_page = $scope.list[$scope.pagination_page - 1][0];
        };
        $scope.nextPageList = () => {
            $scope.pagination_page++;
            if ($scope.pagination_page > $scope.pagination_page_number) return $scope.pagination_page = $scope.pagination_page_number;
            $scope.data_page = $scope.list[$scope.pagination_page - 1][0];
        };
        $scope.toPage = page => {
            $scope.data_page = page;
        };
        $scope.startPageList = () => {
            this.$scope.pagination_page = 1;
            $scope.data_page = 1;
        };
        $scope.endPageList = () => {
            this.$scope.pagination_page = this.$scope.pagination_page_number;
            this.$scope.data_page = this.$scope.page_size;
        };
    }

    init() {
        //数据总页数
        this.$scope.page_size = Math.ceil(this.$scope.data_size / this.$scope.data_limit);
        //页签
        this.$scope.pagination_page = this.$scope.pagination_page || 1;
        this.$scope.pagination_page_show = this.$scope.pagination_page_show || 10;
        //页签总批数
        this.$scope.pagination_page_number = Math.ceil(this.$scope.page_size / this.$scope.pagination_page_show);
        //分页页签二维数组
        this.$scope.list = [];

        for (let i = 0; i < this.$scope.pagination_page_number; i++) {
            if (!this.$scope.list[i]) this.$scope.list[i] = [];
            let skip = this.$scope.pagination_page_show * i;
            let max = skip + this.$scope.pagination_page_show;
            if (max > this.$scope.page_size) max = this.$scope.page_size;
            for (; skip < max; skip++) this.$scope.list[i].push(skip + 1);
        }
    }
}

OMPaginationController.$inject = ['$scope'];
app.controller('OMPagination', OMPaginationController);