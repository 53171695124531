const app = angular.module('editor');
import ResourceTemplate from '../../views/directive/resource.jade'

app.directive('omResource', function () {
    return {
        template: ResourceTemplate,
        controller: 'OMResource',
        scope: {
            type: '=',
            gameId: '=',
            onselect: '=',
            showType: '=?',
            domId: '=?',
        }
    };
});


class OMResourceController {
    constructor($scope, $rootScope, ApiService, $alert, $event) {

        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.ApiService = ApiService;
        this.$alert = $alert;
        this.$event = $event;

        this.init()
    }

    init() {
        let self = this;
        self.$scope.permission = 'editor';
        self.$scope.canSelectType = true;
        self.$scope.showType = 'check';
        self.$scope.canSelectTypeArray = ['image', 'gif', 'audio', 'video', 'imageAndText'];
        self.$scope.fileType = self.$scope.canSelectTypeArray[0];
        if (!self.$scope.domId) self.$scope.domId = '';
        self.$scope._ = {
            imageGroupName: '',
            isGroup: dir => dir.split('/').length < 3,
            inGroup: (name, group) => {
                if (group === '') return true;
                if (group === 'no_group') {
                    if (name.split('/').length < 2) return true;
                } else return name.startsWith(group);
            },
            getGroupName: dir => dir.split('/')[0],
            onUploadBtnClick() {
                $('#directive_game_resource_upload_modal_' + self.$scope.domId).modal('show');
            },
            hideUploadModal() {
                $('#directive_game_resource_upload_modal_' + self.$scope.domId).modal('hide');
            }
        };

        self.$scope.openCreateImageAndTextPage = () => {
            let host = window.location.href.replace(window.location.hash, '');
            window.open(host + '#!/game/detail/' + self.$scope.gameId + '/resource?pageType=imageAndText&action=showImageAndText', '_blank');
        };
        self.$scope.reload = () => {
            self.initData().then();
        };
        self.$scope.selectType = (type) => {
            self.$scope.fileType = type;
        };
        self.$scope.chooseResource = (resource) => {
            resource.url = self.$scope.getGameResourceUrl(resource);
            if (self.$scope.onselect && typeof self.$scope.onselect === 'function') self.$scope.onselect(resource);
        };
        self.$scope.getImageStyle = (resource = {}, maxImageWidth) => {
            let [maxWidth, maxHeight] = [maxImageWidth || 256, 180];
            let [Width, Height] = [Number(resource.ImageWidth), Number(resource.ImageHeight)];
            let [width, height] = [maxWidth, maxHeight];
            let [marginX, marginY] = [0, 0];
            if (Width > Height) {
                height = Height * width / Width;
                if (height > maxHeight) {
                    width = Width * width / Width * maxHeight / height;
                    height = maxHeight;
                    marginX = (maxWidth - width) / 2;
                }
                marginY = (maxHeight - height) / 2;
            } else {
                width = Width * height / Height;
                marginX = (maxWidth - width) / 2;
            }
            return {
                width: width + 'px',
                height: height + 'px',
                marginTop: marginY + 'px',
                marginRight: marginX + 'px',
                marginBottom: marginY + 'px',
                marginLeft: marginX + 'px',
            };
        };

        self.$scope.showResource = resource => {
            self.$scope.select_resource = resource;
            $('#game_editor_resource_info_modal_' + self.$scope.domId).modal('show');
        };

        self.$scope.closeResource = () => {
            self.$scope.select_resource = null;
            $('#game_editor_resource_info_modal_' + self.$scope.domId).modal('hide');
        };

        self.$scope.getGameResourceUrl = resource => {
            if (!resource || !resource.name) return;
            return `${self.$scope.resourcePrefix}${resource.name}`;
        };
        self.$scope.copyUrl = resource => {
            let url = self.$scope.getGameResourceUrl(resource);
            try {
                let aux = document.createElement('input');
                aux.setAttribute('value', url);
                document.body.appendChild(aux);
                aux.select();
                document.execCommand('copy');
                document.body.removeChild(aux);
                self.$alert.success('复制成功');
            } catch (err) {
                self.$alert.error('复制失败');
                console.log('err', err)
            }
        };

        self.$scope.onUpload = async () => {
            await self.initData();
            self.$event.emit('game_resource_update_' + self.$scope.gameId);
        };

        self.$scope.$watch('type', type => {
            if (type && typeof type === 'string') {
                self.$scope.canSelectTypeArray = type.split('&');
                self.$scope.fileType = self.$scope.canSelectTypeArray[0];
                self.$scope.canSelectType = self.$scope.canSelectTypeArray.length > 1;
            } else {
                self.$scope.canSelectTypeArray = ['image', 'gif', 'audio', 'video', 'imageAndText'];
                self.$scope.fileType = 'image';
                self.$scope.canSelectType = true;
            }
        });

        self.initData().then();
    }

    async initData() {
        try {
            let editorGame = await this.ApiService.getEditorGameDetail(this.$scope.gameId);
            let releaseDate = new Date(editorGame.releaseDate);
            releaseDate.setSeconds(0);
            releaseDate.setMilliseconds(0);
            editorGame.releaseDate = releaseDate;
            if (!this.$rootScope.__.GAMES) this.$rootScope.__.GAMES = {};
            if (!this.$rootScope.__.GAMES[this.$scope.gameId]) this.$rootScope.__.GAMES[this.$scope.gameId] = {};
            this.$rootScope.__.GAMES[this.$scope.gameId].dev = editorGame;
            this.$scope.$apply(() => {
                this.$scope.resources = editorGame.others.resources;
                this.$scope.resourcePrefix = editorGame.others.resourcePrefix;
            });
        } catch (error) {
            console.error('web:editor:TemplateResourceController:initData:error:', error);
            this.$alert.error(error.data && error.data.status && error.data.status.message);
        }
    }

}

OMResourceController.$inject = ['$scope', '$rootScope', 'ApiService', '$alert', '$event'];
app.controller('OMResource', OMResourceController);