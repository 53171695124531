const app = angular.module('editor');

app.filter('url', () => {
    return url => url && /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/.test(url) ? url : '';
});

app.filter('isMultiGame', () => {
    return isMultiGame => isMultiGame ? '多人游戏' : '单人游戏';
});

app.filter('isMulti', () => {
    return isMulti => isMulti ? '多人' : '单人';
});

app.filter('templatesGameType', () => {
    let obj = {
        Talk: '对话体小说',
        Novel: '互动小说',
        AVG: 'AVG',
    };
    return templatesGameType => obj[templatesGameType] || templatesGameType || '默认类型';
});

app.filter('templatesGameStyle', () => {
    let obj = {
        Classi: '古风',
        Scifi: '科幻',
        Simple: '简约',
    };
    return templatesGameStyle => obj[templatesGameStyle] || templatesGameStyle || '默认风格';
});

app.filter('fileSize', () => {
    return size => {
        let m = Math.round(Number(size) / 1024 / 1024 * 100) / 100;
        let k = Math.round(Number(size) / 1024 * 100) / 100;
        return m > 1 ? m + 'MB' : k + 'KB';
    };
});