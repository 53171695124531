const app = angular.module('editor');

app.factory('$progress', ['$timeout', '$rootScope', function ($timeout, $rootScope) {
    let types = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];

    function Progress(number = 0, type) {
        if (!types.includes(type)) type = 'primary';
        Progress.number = number;
        Progress.class = 'bg-' + type;
        Progress.style = {width: number + '%'};
        Progress.show = true;
        Progress.status = 'on';
        Progress.run().then();
    }

    Progress.number = 0;
    Progress.class = 'bg-primary';
    Progress.style = {width: '0%'};
    Progress.show = false;
    Progress.status = 'off';

    Progress.hide = () => {
        Progress.number = 0;
        Progress.class = 'bg-primary';
        Progress.style = {width: '0%'};
        Progress.show = false;
        Progress.status = 'off';
        $timeout(() => $rootScope.$apply());
    };
    Progress.end = async () => {
        Progress.status = 'off';
        let n = Math.ceil(Progress.number);
        let i = 100 - n;
        for (; n <= 100; n += 5) {
            Progress.update(n);
            await sleep(500 / i / 5);
        }
        await sleep(500);
        $timeout(() => Progress.hide());
    };

    Progress.run = async () => {
        let n = Math.ceil(Progress.number);
        for (; n <= 99; n += 2) {
            if (Progress.status === 'off') return;
            Progress.update(n);
            await sleep(250);
        }
    };

    Progress.update = number => {
        Progress.number = number;
        Progress.style = {width: number + '%'};
        $timeout(() => $rootScope.$apply());
    };

    function sleep(time) {
        return new Promise(resolve => $timeout(() => resolve(), time))
    }

    return Progress;
}]);
