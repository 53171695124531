const app = angular.module('editor');

app.factory('$util', function () {
    return {
        //获取间隔时间
        getTimeInterval(t1, t2) {
            let time1 = new Date(t1);
            let time2 = new Date(t2);
            let i_ms = Math.abs(time1 - time2);
            let i;
            let i_s = Math.floor(i_ms / 1000);
            if (i_s) i = i_s + '秒';
            let i_m = Math.floor(i_ms / 1000 / 60);
            if (i_m) i = i_m + '分钟';
            let i_h = Math.floor(i_ms / 1000 / 60 / 60);
            if (i_h) i = i_h + '小时';
            let i_d = Math.floor(i_ms / 1000 / 60 / 60 / 24);
            if (i_d) i = i_d + '天';
            let i_y = Math.floor(i_ms / 1000 / 60 / 60 / 24 / 365.24219);
            if (i_y) i = i_y + '年';
            return i;
        },
        formatDate(date, fmt) {
            if (!date && !fmt) date = 'yyyy-MM-dd hh:mm:ss';
            if (!fmt && typeof date === 'string') {
                date = new Date();
                fmt = date;
            }
            let o = {
                "M+": date.getMonth() + 1, //月份
                "d+": date.getDate(), //日
                "h+": date.getHours(), //小时
                "m+": date.getMinutes(), //分
                "s+": date.getSeconds(), //秒
                "q+": Math.floor((date.getMonth() + 3) / 3), //季度
                "S": date.getMilliseconds() //毫秒
            };
            if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
            for (let k in o)
                if (/"(" + k + ")"/.test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        },
        //字符串位数不足时前方补0
        addPreZero(n, size) {
            let l = String(n).length, s = '';
            for (let i = 0; i < size - l; i++) s += '0';
            return s + String(n);
        },
        //获取对象子项数量
        getObjectSize(object = {}) {
            return Object.keys(object).length;
        },
        clone(obj) {
            return JSON.parse(JSON.stringify(obj))
        },
        copy2Clipboard(text) {
            try {
                let aux = document.createElement('input');
                aux.setAttribute('value', text);
                document.body.appendChild(aux);
                aux.select();
                document.execCommand('copy');
                document.body.removeChild(aux);
                return 'success';
            } catch (err) {
                throw err;
            }
        }

    };
});
