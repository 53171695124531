const app = angular.module('editor');

const UserStore = {};
const UIDMap = {};

let UserFactory = function ($http) {
    return {
        async getUser({userId, uid}, options = {}) {
            if (userId) {
                if (!options.new && UserStore[userId]) return UserStore[userId];
                return await this.getUserByServer({userId, uid});
            } else if (uid) {
                if (!options.new && UIDMap[uid] && UserStore[UIDMap[uid]]) return UserStore[UIDMap[uid]];
                return await this.getUserByServer({userId, uid});
            } else {
                throw Error('查询条件错误!');
            }
        },

        async getUserByServer({userId, uid}) {
            let query = '';
            if (userId) query = 'userId=' + userId;
            else if (uid) query = 'uid=' + uid;
            else throw Error('查询条件错误!');
            try {
                let result = await $http.get(`${BASEURL}/author/account/public?${query}`);
                let user = result.data.data;
                if (!user) {
                    user = {userId, uid, others: {nickname: uid || '谜案馆用户'}}
                }
                UserStore[user.userId] = user;
                UIDMap[user.uid] = user.userId;
                return user;
            } catch (e) {
                console.error(e);
                return null;
            }
        },

    }
}


UserFactory.$inject = ['$http'];
app.factory('$user', UserFactory);
