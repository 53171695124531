const app = angular.module('editor');

class ApiService {
    constructor($rootScope, $storage, $location, $event, HttpService) {
        this.$rootScope = $rootScope;
        this.$storage = $storage;
        this.$location = $location;
        this.$event = $event;
        this.HttpService = HttpService;
    }

    async _refreshGamesSynopsis() {
        let devGames = await this.getEditorGamesSynopsis();
        let testGames = await this.getTestGamesSynopsis();
        let proGames = await this.getProGamesSynopsis();

        for (let game of devGames) {
            if (!this.$rootScope.__.GAMES[game.gameId]) throw Error('异常游戏ID: ' + game.gameId);
            this.$rootScope.__.GAMES[game.gameId].dev = game;
        }
        for (let game of testGames) {
            if (!this.$rootScope.__.GAMES[game.gameId]) throw Error('异常游戏ID: ' + game.gameId);
            this.$rootScope.__.GAMES[game.gameId].test = game;
        }
        for (let game of proGames) {
            if (!this.$rootScope.__.GAMES[game.gameId]) throw Error('异常游戏ID: ' + game.gameId);
            this.$rootScope.__.GAMES[game.gameId].pro = game;
        }
        this.$rootScope.$apply();
    }


    login({phone, password, remember}) {
        return this.HttpService.post('/author/login/phone/password', {phone, password})
            .then(async user => {
                let games = await this.getAuthorGameList(user.userId);
                this.$event.emit('login', user, games);
                if (remember) this.$storage.setObject('__ME', user);
                this.$storage.set('loginTime', Date.now());
                return user;
            });
    }

    loginByToken({userId = '', token = ''}) {
        return this.HttpService.post('/author/login/userId/token', {userId, token})
            .then(async user => {
                let games = await this.getAuthorGameList(user.userId);
                this.$event.emit('login', user, games);
                this.$storage.setObject('__ME', user);
                this.$storage.set('loginTime', Date.now());
                return user;
            });
    }

    async reloadMeDataOnAddGamePermissions(gameId, editorUserId) {
        if (editorUserId !== this.$rootScope.__.ME.userId) return;
        try {
            let user = await this.getEditorUserInfo({
                userId: this.$rootScope.__.ME.userId || '',
                token: this.$rootScope.__.ME.token || ''
            });
            this.$storage.setObject('__ME', user);
            if (!this.$rootScope.__.GAMES[gameId]) this.$rootScope.__.GAMES[gameId] = user.games[gameId];
        } catch (error) {
            console.error('reloadMeData:Error:', error);
        }
    }

    async reloadMeDataOnDeleteGamePermissions(gameId) {
        try {
            let user = await this.getEditorUserInfo({
                userId: this.$rootScope.__.ME.userId || '',
                token: this.$rootScope.__.ME.token || ''
            });
            user.token = this.$rootScope.__.ME.token || '';
            this.$storage.setObject('__ME', user);
        } catch (error) {
            console.error('reloadMeData:Error:', error);
        }
    }

    logout() {
        this.$event.emit('logout');
        // this.$location.path('/login');
        //todo ws disconnect
        //todo 通知服务器离线
    }

    getEditorUserInfo(query) {
        let querystring = '';
        for (let attr in query) querystring += `&${attr}=${query[attr]}`
        querystring = querystring.substring(1);
        return this.HttpService.get('/editor/user?' + querystring);
    }

    getAuthorAccountByUID(uid) {
        return this.HttpService.get(`/author/account/uid?uid=${uid}`);
    }

    getAuthorAccountByUserId(userId) {
        return this.HttpService.get(`/author/account/userId?userId=${userId}`);
    }

    getAuthorAccountByToken({userId, token}) {
        return this.HttpService.get(`/author/account/token?userId=${userId}&token=${token}`);
    }

    getAuthorGameList(userId) {
        return this.HttpService.get('/author/game/list?userId=' + userId);
    }

    postAuthorAccountContact({email, phone}) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.put('/author/account/contact', {
            userId: this.$rootScope.__.ME.userId,
            email,
            phone,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    postEditorUserPreference({language}) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/user/preference', {
            userId: this.$rootScope.__.ME.userId,
            language: language,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    existEditorUser(query) {
        let querystring = '';
        for (let attr in query) querystring += `&${attr}=${query[attr]}`
        querystring = querystring.substring(1);
        return this.HttpService.get('/editor/user/exist?' + querystring);
    }


    newGame(game) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/new', {
            userId: this.$rootScope.__.ME.userId,
            game: game
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    deleteGame(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/game?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    generateMultiGame(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/generateMulti', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getEditorGamesSynopsis() {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/games/synopsis?userId=' + this.$rootScope.__.ME.userId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    postEditorUserGameTop(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/user/game/top', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    deleteEditorUserGameTop(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/user/game/top?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getTestGamesSynopsis() {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/games/test/synopsis?userId=' + this.$rootScope.__.ME.userId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getProGamesSynopsis() {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/games/pro/synopsis?userId=' + this.$rootScope.__.ME.userId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getEditorGameSynopsis(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/synopsis?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getEditorGameDetail(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/detail/?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }


    getAuthorGameSynopsisPro(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/author/game/synopsis/pro?gameId=${gameId}`, {
            headers: {
                userid: this.$rootScope.__.ME.userId,
                token: this.$rootScope.__.ME.token
            }
        })
    }

    // postEditorGameDetail(gameId, game, commit) {
    //     if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
    //     return this.HttpService.post('/editor/game/detail', {
    //         userId: this.$rootScope.__.ME.userId,
    //         gameId: gameId,
    //         game: game,
    //         commit: commit
    //     }, {headers: {token: this.$rootScope.__.ME.token}});
    // }

    putEditorGameDetail(gameId, updateData, commit) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.put('/editor/game/detail', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
            updateData: updateData,
            commit: commit
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    postEditorGameConfig(gameId, game) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        let _game = JSON.parse(JSON.stringify(game));
        if (_game.data) delete _game.data.modules;
        if (_game.data) delete _game.data.props;
        return this.HttpService.post('/editor/game/detail/config', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
            game: _game,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getEditorGameHistoryDetail(gameId, version) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/history/detail?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&version=' + version, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getEditorGameUpdateLogDetail(gameId, version) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/updatelog/detail?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&version=' + version, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getEditorGameHistory(gameId, page) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/history?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&page=' + page, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getTestGameHistory(gameId, page) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/test/history?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&page=' + page, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getProGameHistory(gameId, page) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/pro/history?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&page=' + page, {headers: {token: this.$rootScope.__.ME.token}});
    }

    postTestGameStatus(gameId, status) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/test/status', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
            status: status,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    postProGameStatus(gameId, status) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/pro/status', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
            status: status,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getEditorGameConfig(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/config?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    postEditorGameEditorConfig(gameId, config) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/config', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
            config: config
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    putEditorGameEditorConfig(gameId, updateIndexConfig) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.put('/editor/game/config', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
            updateIndexConfig: updateIndexConfig
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    releaseDevGameToTest(gameId, message) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/release/test', {
            userId: this.$rootScope.__.ME.userId,
            gameId,
            message,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    auditTestGameToPro(gameId, message) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/audit', {
            userId: this.$rootScope.__.ME.userId,
            gameId,
            message,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    cancelAuditTestGameToPro(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/game/audit?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }


    releaseTestGameToPro(gameId, message) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/release/pro', {
            userId: this.$rootScope.__.ME.userId,
            gameId,
            message,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    releaseTestServerEditorGameToTestAndPro(gameId, message) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/release/all', {
            userId: this.$rootScope.__.ME.userId,
            gameId,
            message,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getTestGameData(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/test?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    GetTestGameSynopsis(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/test/synopsis?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getProGameData(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/pro?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getProGameSynopsis(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/pro/synopsis?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getGameVersion(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/version?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getGameSTSToken(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/ststoken?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    postGameResources(gameId, resources) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/resource/config', {
            userId: this.$rootScope.__.ME.userId,
            gameId,
            resources,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    deleteGameResources(gameId, resources) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/game/resource/config?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&resources=' + JSON.stringify(resources), {headers: {token: this.$rootScope.__.ME.token}});
    }

    postGameImageAndText(gameId, name, value) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/resource/imageAndText', {
            userId: this.$rootScope.__.ME.userId,
            gameId,
            name,
            value,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    putGameImageAndText(gameId, key, name, value) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.put('/editor/game/resource/imageAndText', {
            userId: this.$rootScope.__.ME.userId,
            gameId,
            key,
            name,
            value,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    deleteGameImageAndText(gameId, key) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/game/resource/imageAndText?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&key=' + key, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getGameResourcesLog(gameId, page) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/resource/config/log?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&page=' + page, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getGameEditorCollaborationList(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/collaborations?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    postGameEditorCollaboration(gameId, collaboration) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/collaboration', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
            collaboration: collaboration,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    deleteGameEditorCollaboration(gameId, collaborationId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/game/collaboration?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&collaborationId=' + collaborationId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getGameTesterList(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/testers?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    postGameTester(gameId, playerId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/tester', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
            playerId: playerId,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    deleteGameTester(gameId, playerId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/game/tester?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&playerId=' + playerId, {headers: {token: this.$rootScope.__.ME.token}});
    }


    getPlayerInfo(query) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        let querystring = '';
        for (let attr in query) querystring += `&${attr}=${query[attr]}`
        querystring = querystring.substring(1);
        return this.HttpService.get('/editor/player?' + querystring);
    }

    uploadGameExcel(data) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/import/excel', data, {
            headers: {
                token: this.$rootScope.__.ME.token,
                'Content-Type': undefined
            }
        });
    }

    uploadGameNpcWord(data) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/plugin/importMarvelArchiveProp/import', data, {
            headers: {
                token: this.$rootScope.__.ME.token,
                'Content-Type': undefined
            }
        });
    }

    getEditorUserMessage(page) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/messages?userId=' + this.$rootScope.__.ME.userId + '&page=' + page, {headers: {token: this.$rootScope.__.ME.token}});
    }

    countEditorUserMessage(status) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        let params = status ? '&status=' + status : '';
        return this.HttpService.get('/editor/messages/count?userId=' + this.$rootScope.__.ME.userId + params, {headers: {token: this.$rootScope.__.ME.token}});
    }

    updateEditorMessageStatus(msgIds, status) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/messages/status', {
            userId: this.$rootScope.__.ME.userId,
            msgIds: msgIds,
            status: status,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getXiuMiLoginUrl() {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/open/url?userId=' + this.$rootScope.__.ME.userId, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getXiuMiArticleList(page, pageSize, title) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/editor/xiumi/list?userId=${this.$rootScope.__.ME.userId}&currentPage=${page}&pageMax=${pageSize}&title=${title}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    deleteXiuMiArticle(from_id) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/xiumi/article?&from_id=' + from_id, {headers: {token: this.$rootScope.__.ME.token}})
    }

    gameCommentList(gameId, currentPage, pageSize, sortType = "date") {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/author/game/community/activity/list?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&currentPage=' + currentPage + '&pageSize=' + pageSize + '&sortType=' + sortType, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getGameEvaluations(gameId, page = 1, sort = 'date', sortType = -1) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/evaluations?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&page=' + page + '&sort=' + sort + '&sortType=' + sortType, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getCarefullyGameEvaluations(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/editor/game/evaluations/carefully?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId, {headers: {token: this.$rootScope.__.ME.token}})
    }

    postGameEvaluationCarefully(gameId, evaluationId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/evaluation/carefully', {
            userId: this.$rootScope.__.ME.userId,
            gameId,
            evaluationId,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    deleteGameEvaluationCarefully(gameId, evaluationId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/game/evaluation/carefully?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&evaluationId=' + evaluationId, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getGameCommunityActivityListByNickname(gameId, currentPage, pageSize, nickname) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get('/author/game/community/activity/list/nickname?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&currentPage=' + currentPage + '&pageSize=' + pageSize + '&nickname=' + nickname, {headers: {token: this.$rootScope.__.ME.token}})
    }

    delCommentReplay(gameId, commentId, replyId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/comment/reply?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&commentId=' + commentId + '&replyId=' + replyId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    delComment(gameId, commentId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/comment?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&commentId=' + commentId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    addCommentReplay(gameId, commentId, byUserId, content) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/comment/reply', {
            userId: this.$rootScope.__.ME.userId,
            gameId: gameId,
            commentId: commentId,
            byUserId: byUserId,
            content: content,
            autoUserId: this.$rootScope.__.ME.userId,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getGamePlayers(gameId, page, uid) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/editor/game/players?userId=${this.$rootScope.__.ME.userId}&gameId=${gameId}&page=${page}&uid=${uid}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    countGamePlayers(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/editor/game/player/count?userId=${this.$rootScope.__.ME.userId}&gameId=${gameId}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    deletePlayerGameRecord(gameId, playerId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/editor/player/game/record?userId=' + this.$rootScope.__.ME.userId + '&gameId=' + gameId + '&playerId=' + playerId, {headers: {token: this.$rootScope.__.ME.token}});
    }

    getGameAnalysis(gameId, type, start, end) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/editor/game/analysis?userId=${this.$rootScope.__.ME.userId}&gameId=${gameId}&type=${type}&start=${start}&end=${end}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getEditorPlugin(pluginId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/editor/plugin?userId=${this.$rootScope.__.ME.userId}&pluginId=${pluginId}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getEditorPlugins() {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/editor/plugins?userId=${this.$rootScope.__.ME.userId}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getEditorPluginGames(pluginId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/editor/plugin/games?userId=${this.$rootScope.__.ME.userId}&pluginId=${pluginId}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getEditorGamePlugins(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/editor/game/plugins?userId=${this.$rootScope.__.ME.userId}&gameId=${gameId}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    postEditorGamePlugin(gameId, pluginId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/editor/game/plugin', {
            userId: this.$rootScope.__.ME.userId,
            gameId,
            pluginId,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    deleteEditorGamePlugin(gameId, pluginId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete(`/editor/game/plugin?userId=${this.$rootScope.__.ME.userId}&gameId=${gameId}&pluginId=${pluginId}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getCDK(gameId, {NO, content}) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/admin/author/cdk?userId=${this.$rootScope.__.ME.userId}&gameId=${gameId}&NO=${NO}&content=${content}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    deleteUserGamePermission(playerId, cdk) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete(`/admin/author/player/game/permission?userId=${this.$rootScope.__.ME.userId}&playerId=${playerId}&cdk=${cdk}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getGameSensitiveAnswer(gameId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/admin/author/game/sensitive/answer?userId=${this.$rootScope.__.ME.userId}&gameId=${gameId}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    putGameSensitiveAnswer(gameId, index, word) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.put('/admin/author/game/sensitive/answer', {
            userId: this.$rootScope.__.ME.userId,
            gameId,
            index,
            word,
        }, {headers: {token: this.$rootScope.__.ME.token}});
    }

    deleteGameSensitiveAnswer(gameId, index) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete(`/admin/author/game/sensitive/answer?userId=${this.$rootScope.__.ME.userId}&gameId=${gameId}&index=${index}`, {headers: {token: this.$rootScope.__.ME.token}})
    }

    getAuthorGameAnnouncementList(gameId, page, limit) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/author/game/announcement/list?gameId=${gameId}&page=${page}&limit=${limit}`, {
            headers: {
                userid: this.$rootScope.__.ME.userId,
                token: this.$rootScope.__.ME.token
            }
        })
    }

    postAuthorGameAnnouncement(gameId, content, h5link, status) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.post('/author/game/announcement', {gameId, content, h5link, status}, {
            headers: {
                userid: this.$rootScope.__.ME.userId,
                token: this.$rootScope.__.ME.token
            }
        });
    }

    putAuthorGameAnnouncement(announcementId, content, h5link, status) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.put('/author/game/announcement', {announcementId, content, h5link, status}, {
            headers: {
                userid: this.$rootScope.__.ME.userId,
                token: this.$rootScope.__.ME.token
            }
        });
    }

    deleteAuthorGameAnnouncement(announcementId) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.delete('/author/game/announcement?announcementId=' + announcementId, {
            headers: {
                userid: this.$rootScope.__.ME.userId,
                token: this.$rootScope.__.ME.token
            }
        });
    }

    putAuthorGameSaleData(gameId, saleVolume, saleAmount) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.put('/author/game/sale/data', {gameId, saleVolume, saleAmount}, {
            headers: {
                userid: this.$rootScope.__.ME.userId,
                token: this.$rootScope.__.ME.token
            }
        });
    }

    getAuthorCommunityTeamDeclaration() {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.get(`/author/community/team/declaration`, {
            headers: {
                userid: this.$rootScope.__.ME.userId,
                token: this.$rootScope.__.ME.token
            }
        })
    }

    putAuthorCommunityTeamDeclaration(declaration) {
        if (!this.$rootScope.__.ME || !this.$rootScope.__.ME.userId || !this.$rootScope.__.ME.token) return this.logout();
        return this.HttpService.put(`/author/community/team/declaration`, {declaration}, {
            headers: {
                userid: this.$rootScope.__.ME.userId,
                token: this.$rootScope.__.ME.token
            }
        })
    }
}

ApiService.$inject = ['$rootScope', '$storage', '$location', '$event', 'HttpService'];

app.service('ApiService', ApiService);
