const app = angular.module('editor');

app.factory('$ws', ['$timeout', '$interval', '$event', function ($timeout, $interval, $event) {
    let client = null;
    let counter = 0;
    let heartbeat_interval;
    let is_connecting = false;
    let is_connected = false;

    $timeout(connect);

    function connect() {
        if (is_connecting) return;
        if (is_connected) return;
        client = null;
        is_connecting = true;
        try {
            let url = BASEURL.replace('http', 'ws') + /ws/;
            url = url.replace('author.itaotuo.com', 'game.itaotuo.com');
            console.log('ws connecting: ', url);
            client = new WebSocket(url);
            client.onopen = () => {
                is_connected = true;
                if (counter) console.log('ws重新连接');
                console.log('ws已连接');
                counter++;
                client.send(JSON.stringify({
                    platform: 'editor',
                    method: 'init',
                }));
                if (heartbeat_interval) $interval.cancel(heartbeat_interval);
                heartbeat_interval = $interval(() => {
                    if (client.readyState !== client.OPEN) {
                        return $interval.cancel(heartbeat_interval);
                    }
                    client.send('ping')
                }, 5000);
            };
            client.onclose = e => {
                is_connected = false;
                is_connecting = false;
                client = null;
                $event.emit('ws_close');
                if (heartbeat_interval) $interval.cancel(heartbeat_interval);
                if (4001 === e.code) {
                    return console.log('ws注销', new Date());
                }
                if (3002 === e.code) {
                    $event.emit('login_qrcode_timeout');
                    console.log('二维码超时')
                    return;
                }
                console.error('服务器关闭', new Date(), e);
                $timeout(() => connect(), 3000);
            };
            client.onerror = e => {
                is_connected = false;
                console.error('连接出错', e);
            };
            client.onmessage = message => {
                if ('pong' === message.data) return;
                let data = message.data;
                try {
                    data = JSON.parse(data);
                } catch (e) {
                    console.error('消息格式错误', message);
                }
                // console.log('ws:data:', data);
                $event.emit('ws.' + data.method, data);
            };
        } catch (e) {
            $timeout(() => connect(), 3000);
        }
    }

    async function login(user) {
        (await getClient()).send(JSON.stringify({
            platform: 'editor',
            method: 'auth',
            data: {userId: user.userId, token: user.token}
        }));
    }

    async function generateLoginQrcode() {
        (await getClient()).send(JSON.stringify({
            platform: 'editor',
            method: 'generate_login_qrcode',
            data: {}
        }));
    }

    function close() {
        client && client.close(4001);
    }

    async function send(data) {
        (await getClient()).send(JSON.stringify({method: 'editor_message', data}));
    }

    async function getClient() {
        if (is_connected) return client;
        else {
            await sleep(600);
            return getClient();
        }
    }

    async function sleep(time) {
        return new Promise(resolve => $timeout(() => resolve(), time));
    }

    return {
        connect,
        generateLoginQrcode,
        login,
        close,
        send,
    };
}]);
