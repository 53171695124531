const app = angular.module('editor');

class HttpService {
    constructor($http, $event) {
        this.$http = $http;
        this.$event = $event;
    }

    request(...args) {
        return this.$http(...args).then(this.parseResultData);
    }

    get(url, options) {
        return this.$http.get(this.parseUrl(url), options).then(this.parseResultData);
    }

    delete(url, options) {
        return this.$http.delete(this.parseUrl(url), options).then(this.parseResultData);
    }

    head(url, options) {
        return this.$http.head(this.parseUrl(url), options).then(this.parseResultData);
    }

    jsonp(url, options) {
        return this.$http.jsonp(this.parseUrl(url), options).then(this.parseResultData);
    }


    post(url, data, options) {
        return this.$http.post(this.parseUrl(url), data, options).then(this.parseResultData);
    }

    put(url, data, options) {
        return this.$http.put(this.parseUrl(url), data, options).then(this.parseResultData);
    }

    patch(url, data, options) {
        return this.$http.patch(this.parseUrl(url), data, options).then(this.parseResultData);
    }


    parseUrl(url) {
        if (url.startsWith('http')) return url;
        return BASEURL + url;
    }

    parseResultData(res) {
        if (res && typeof res.data === 'object' && res.data.hasOwnProperty('data')) return res.data.data;
        return res.data;
    }

}

HttpService.$inject = ['$http', '$event'];

app.service('HttpService', HttpService);
