const app = angular.module('editor');

app.directive('omFile', function () {
    return {
        scope: {
            omFile: '='
        },
        link: (scope, element) => {
            element.bind('change', changeEvent => {
                scope.$apply(() => {
                    scope.omFile = [];
                    for (let file of changeEvent.target.files) scope.omFile.push(file);
                    for (let ele of element) if (ele.value) ele.value = null;
                });
            });
        }
    }
});

