const app = angular.module('editor');

app.factory('$modal', function () {

    function Modal(title, message, options) {
        if (!title && !message) return;
        let _options = {
            backdrop: true,
            keyboard: true,
            focus: true,
            show: true,
            title: title,
            message: message,
        };
        if (! _options.message) {
            _options.message = _options.title;
            _options.title = '';
        }
        if (options && typeof options === 'object') {
            for (let attr in options) _options[attr] = options[attr];
        }
        if (_options.cancel_text) Modal.cancel_text = _options.cancel_text;
        if (_options.ok_text) Modal.ok_text = _options.ok_text;

        show(_options);
    }

    Modal.title = '';
    Modal.message = '';
    Modal.cancel_text = '取消';
    Modal.ok_text = '确定';
    Modal.on_ok = null;
    Modal.on_cancel = null;

    function show(options) {
        Modal.title = options.title;
        Modal.message = options.message;
        $('#__modal').modal({
            backdrop: options.backdrop,
            keyboard: options.keyboard,
            focus: options.focus,
            show: options.show,
        });
    }


    $('#__modal').on('hidden.bs.modal', function () {
        Modal.title = '';
        Modal.message = '';
        Modal.cancel_text = '取消';
        Modal.ok_text = '确定';
        Modal.on_ok = null;
        Modal.on_cancel = null;
    });

    Modal.ok = () => {
        if (Modal.on_ok && typeof Modal.on_ok === 'function') Modal.on_ok();
        else $('#__modal').modal('hide');
    };
    Modal.cancel = () => {
        if (Modal.on_cancel && typeof cb === 'function') Modal.on_cancel();
        else $('#__modal').modal('hide');
    };
    Modal.show = () => {
        $('#__modal').modal('show');
    };
    Modal.hide = () => {
        $('#__modal').modal('hide');
    };

    return Modal;
});
