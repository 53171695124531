const app = angular.module('editor');

app.directive('omRightClick', ['$parse', function ($parse) {
    return {
        link: ($scope, element, attrs) => {
            element.bind('contextmenu', function (event) {
                event.preventDefault();
                $parse(attrs.omRightClick)($scope, {$event: event});
            })
        },
    };
}]);
