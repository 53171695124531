const app = angular.module('editor');

app.factory('$alert', ['$timeout', '$rootScope', function ($timeout, $rootScope) {
    let id = 0;
    let count = 0;
    let types = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];
    let queue = [];
    let timers = {};

    function Alert(message, type, options) {
        alert(message, type, options);
    }

    Alert.style = '';
    Alert.strong = '';
    Alert.message = '';
    Alert.show = false;
    Alert.close = close;

    for (let type of types) {
        Alert[type] = msg => alert(msg, type);
    }
    Alert.log = Alert.info;
    Alert.warn = Alert.warning;
    Alert.error = Alert.danger;

    function alert(message, type, options) {
        count++;
        let _options = {id: count, type: 'primary', message: message, time: 2000};
        if (types.includes(type)) _options.type = type;
        if (options && typeof options === 'object') {
            if (Number(options.time)) _options.time = options.time;
            if (options.strong) _options.strong = options.strong;
        }
        showAlert(_options);
    }

    function showAlert(options) {
        if (Alert.show) return queue.push(options);
        id = options.id;
        Alert.style = 'alert-' + options.type;
        Alert.strong = options.strong;
        Alert.message = options.message;
        Alert.show = true;
        timers[options.id] = $timeout(() => {
            if (id === options.id) close();
            else console.error('alert error, ' + id, options.id);
        }, options.time);
        $timeout(() => $rootScope.$apply(), 0);
    }

    function showNext() {
        let options = queue.shift();
        if (options) showAlert(options);
    }

    function close(_id) {
        if (!_id) _id = id;
        Alert.show = false;
        $timeout.cancel(timers[_id]);
        showNext();
    }

    return Alert;
}]);
